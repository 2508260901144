import React from 'react';

import {
  ContactPage,
  Props as ContactPageProps,
} from '@kali/theme/lib/pages/contact/page';

import Layout from '../../components/layout';

export default class extends React.Component {
  static defaultProps = {
    match: {
      params: {
        type: undefined,
      },
    },
  };

  state = {
    isSubmitting: false,
    isComplete: false,
    values: {},
  };

  componentDidMount() {
    document.title = 'Contact Us | Kalina & Co.';
  }

  onChange = (name, value) => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        [name]: value,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const { values } = this.state;
    const body = {
      values,
    };

    this.setState({ isSubmitting: false }, async () => {
      try {
        await require('axios').post(
          'https://us-central1-kalina-2ab49.cloudfunctions.net/api/contact',
          body
        );

        this.setState({ isSubmitting: false, isComplete: true, values: {} });
      } catch (e) {
        this.setState({ isSubmitting: false });
      }
    });
  };

  render() {
    const { isSubmitting, isComplete, values } = this.state;
    const { type = 'couple' } = this.props;

    return (
      <Layout title="Contact">
        <ContactPage
          values={values}
          isSubmitting={isSubmitting}
          isComplete={isComplete}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          type={type}
        />
      </Layout>
    );
  }
}
